import { getApi, postApi } from "./_apihelper";

export const UserRegistration = (data) => (
    postApi(process.env.REACT_APP_USER_REGISTRATION, data, process.env.REACT_APP_USE_MANAGEMENT)
)

export const LoginPage = (data) => (
    postApi(process.env.REACT_APP_USER_LOGIN, data, process.env.REACT_APP_USE_MANAGEMENT)
)

export const ForgotPassword = (data) => (
    postApi(process.env.REACT_APP_FORGOT_PASSWORD, data, process.env.REACT_APP_UTILS)
)

export const VerifyOtp = (data) => (
    postApi(process.env.REACT_APP_VERIFY_OTP, data, process.env.REACT_APP_UTILS)
)

export const GeneratePassword = (data) => (
    postApi(process.env.REACT_APP_GENERATE_PASSWORD, data, process.env.REACT_APP_UTILS)
)

export const GetCustomerId = (data) => (
    postApi(process.env.REACT_APP_GET_CUSTOMER_ID, data, process.env.REACT_APP_UTILS)
)

export const GetCustomFields = (id) => (
    getApi(`${process.env.REACT_APP_GET_CUSTOM_FEILDS}/${id}/customfields`, process.env.REACT_APP_USE_MANAGEMENT)
)

// email templates
export const GetEmailTemplates = (customerId) => (
    getApi(`${process.env.REACT_APP_GET_CUSTOM_FEILDS}/${customerId}/emailtemplates`, process.env.REACT_APP_USE_MANAGEMENT)
)

export const GetEmailTemplatebyId = (customerId, id) => (
    getApi(`${process.env.REACT_APP_GET_CUSTOM_FEILDS}/${customerId}/emailtemplates/${id}`, process.env.REACT_APP_USE_MANAGEMENT)
)
export const SendEmail = (data) => (
    postApi(`${process.env.REACT_APP_SEND_EMAIL}`,data, process.env.REACT_APP_UTILS)
)
export const BulkEmail = (data) => (
    postApi(`${process.env.REACT_APP_BULK_EMAIL}`,data, process.env.REACT_APP_UTILS)
)