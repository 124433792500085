import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './pages/Layout';
// jobposting components
import PostingsDocuments from "./pages/JobPostings/PostingsDocuments/PostingsDocuments";
import PostingsEdit from "./pages/JobPostings/PostingsEdit/PostingsEdit";
import PostingsNotes from "./pages/JobPostings/PostingsNotes/PostingsNotes";
import PostingsTable from "./pages/JobPostings/PostingsTable/PostingsTable";
import PostingsView from "./pages/JobPostings/PostingsView/PostingsView";
// import PostingsCreate from "./pages/JobPostings/PostingsCreate/PostingsCreate";
import PostingsAssociatedApplicants from "./pages/JobPostings/PostingsAssociatedApplicants/JobpostingsAssociatedApplicants";
import PostingsAuditlogging from './pages/JobPostings/PostingsAuditlogging/PostingsAuditlogging';
import PostingsCreate from './pages/JobPostings/PostingsCreate/PostingsCreate';
import PostingsCreateTabs from './pages/JobPostings/PostingsCreate/PostingsCreateTabs';
import JobPortal from "./pages/JobPostings/PostingsJobPortal/JobPortal";
import PostingsPortalsEdit from './pages/JobPostings/PostingsPortals/PostingsPortalsEdit';
import PostingsPortalsView from './pages/JobPostings/PostingsPortals/PostingsPortalsView';

// Applicants components
import ApplicantsAssociatedJobs from "./pages/Applicants/ApplicantsAssociatedJobs/ApplicantAssociatedJobs";
import ApplicantsAuditlogging from './pages/Applicants/ApplicantsAuditlogging/ApplicantsAuditlogging';
import ApplicantsCreate from "./pages/Applicants/ApplicantsCreate/ApplicantsCreate";
import ApplicantsDocuments from "./pages/Applicants/ApplicantsDocuments/ApplicantDocuments";
import ApplicantsEdit from "./pages/Applicants/ApplicantsEdit/ApplicantsEdit";
import ApplicantsNotes from "./pages/Applicants/ApplicantsNotes/ApplicantsNotes";
import ApplicantsTable from "./pages/Applicants/ApplicantsTable/ApplicantsTable";
import ApplicantsView from "./pages/Applicants/ApplicantsView/ApplicantsView";

// user management
import ForgotPassword from "./pages/Landings/ForgotPassword/ForgotPassword";
import Login from "./pages/Landings/LoginForm/Login";
import Register from "./pages/Landings/RegisterForm/Register";
import ResetPassword from "./pages/Landings/ResetPassword/ResetPassword";
import VerifyOTP from "./pages/Landings/VerifyOTP/VerifyOTP";

//Clients Components
import ClientsView from './pages/Clients/ClentsView/ClientsView';
import ClientsAuditlogging from './pages/Clients/ClientsAuditlogging/ClientsAuditlogging';
import ClientsCreate from './pages/Clients/ClientsCreate/ClientsCreate';
import ClientsEdit from './pages/Clients/ClientsEdit/ClientsEdit';
import ClientsTable from './pages/Clients/ClientsTable/ClientsTable';

// Dashboard Component
import ApplicantsDashboard from './pages/Applicants/ApplicantsDashboard/ApplicantsDashboard';

// HelpSection Component
import HelpSectionJobpostings from './pages/HelpSection/HelpSectionJobpostings';
import HelpSectionApplicants from './pages/HelpSection/HelpSectionApplicants';
import HelpSectionEmail from './pages/HelpSection/HelpSectionEmail';

//Customer
import User from './pages/Customer/Users/User'
import Mail from './pages/Customer/Mail/Mail'
import Vendors from './pages/Customer/Vendors/Venders'
import CustomerView from './pages/Customer/CustomerView/CustomerView'
import Custom from './pages/Customer/Custom/Custom'
import JobboardPortalView from './pages/Customer/JobboardPortal/JobboardPortalView'
import JobboardPortalEdit from './pages/Customer/JobboardPortal/JobboardPortalEdit'


import { useLocation } from "react-router-dom";
import './App.css';
import JobpostingsAssociatedApplicantsProfile from './pages/JobPostings/PostingsAssociatedApplicantProfile/PostingsAssociatedApplicantsProfile';
import PageNotFound from './pages/PageNotFound/PageNotFound';

function App() {

  const location = useLocation();
  React.useEffect(() => {
    const url = window.location.pathname;
    const path = url.includes("layout");
    if (path) {
      document.body.classList.remove('bg-yellow');
    } else {
      document.body.classList.add('bg-yellow');
    }
  }, [location]);

  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="ats/layout/dashboard" replace />}></Route>
      <Route path="ats/register" element={<Register />} />
      <Route path="ats/login" element={<Login />} />
      <Route path="ats/forgotpassword" element={<ForgotPassword />} />
      <Route path="ats/reset-password" element={<ResetPassword />} />
      <Route path="ats/verify-otp" element={<VerifyOTP />} />
      <Route path="ats/layout" element={<Layout />}>
        <Route path="dashboard" element={<ApplicantsDashboard />} />
        <Route path="jobpostings/landing" element={<PostingsTable />} />
        <Route path="jobpostings/view/:id" element={<PostingsView />} />
        <Route path="jobpostings/edit/:id" element={<PostingsEdit />} />
        <Route path="jobpostings/documents/:id" element={<PostingsDocuments />} />
        <Route path="jobpostings/associatedapplicants/:id" element={<JobpostingsAssociatedApplicantsProfile />} />
        <Route path="jobpostings/notes/:id" element={<PostingsNotes />} />
        <Route path="jobpostings/screeningquestions/:id" element={<JobPortal />} />
        <Route path='jobpostings/portals/view/:id' element={<PostingsPortalsView />} />
        <Route path='jobpostings/portals/edit/:id' element={<PostingsPortalsEdit />} />
        <Route path="jobpostings/auditinfo/:id" element={<PostingsAuditlogging />} />
        <Route path="jobpostings/create" element={<PostingsCreate />} />
        <Route path="jobpostings/create/jobdetails" element={<PostingsCreateTabs />} />
        <Route path="jobpostings/create/document" element={<PostingsCreateTabs />} />
        <Route path="jobpostings/create/questions" element={<PostingsCreateTabs />} />
        <Route path="jobpostings/create/portals" element={<PostingsCreateTabs />} />

        <Route path="applicants/landing" element={<ApplicantsTable />} />
        <Route path="applicants/view/:id" element={<ApplicantsView />} />
        <Route path="applicants/edit/:id" element={<ApplicantsEdit />} />
        <Route path="applicants/notes/:id" element={<ApplicantsNotes />} />
        <Route path="applicants/documents/:id" element={<ApplicantsDocuments />} />
        <Route path="applicants/create" element={<ApplicantsCreate />} />
        <Route path="applicants/create-duplicate" element={<ApplicantsCreate />} />
        <Route path="applicants/associatedjobs/:id" element={<ApplicantsAssociatedJobs />} />
        <Route path="applicants/associatedjobs/jobview/:id" element={<PostingsView />} />
        <Route path="applicants/auditinfo/:id" element={<ApplicantsAuditlogging />} />
          
        <Route path="clients/landing" element={<ClientsTable />} />
        <Route path="clients/create" element={<ClientsCreate />} />
        <Route path="clients/view/:id" element={<ClientsView />} />
        <Route path="clients/edit/:id" element={<ClientsEdit />} />
        <Route path="clients/auditinfo/:id" element={<ClientsAuditlogging />} />

        <Route path="helpsection/postings" element={<HelpSectionJobpostings />} />
        <Route path="helpsection/applicant" element={<HelpSectionApplicants />} />
        <Route path="helpsection/emailtemplates" element={<HelpSectionEmail />} />

        {/* Customer */}
        <Route path="customer/view" element={<CustomerView />} />
        <Route path="customer/users" element={<User />} />
        <Route path="customer/vendors" element={<Vendors />} />
        <Route path="customer/custom" element={<Custom />} />
        <Route path="customer/jobboardportal/view" element={<JobboardPortalView />} />
        <Route path="customer/jobboardportal/edit" element={<JobboardPortalEdit />} />
        <Route path="customer/organize/mail" element={<Mail />} />

      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;