export const Encrypt = (text, path) => {
    localStorage.removeItem(path);
    sessionStorage.removeItem(path);
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars('subpath').reduce((a, b) => a ^ b, code);
    let encriptString = text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
    localStorage.setItem(path, encriptString);
    if(path === "selectCountryId" || path === "selectCountry"){
        sessionStorage.setItem(path, encriptString);
    }
};

export const decrypt = (path) => {
    let encoded = '';
    if(path === "selectCountryId" || path === "selectCountry"){
        encoded = sessionStorage.getItem(path) ? sessionStorage.getItem(path) : localStorage.getItem(path);
    } else {
        encoded = localStorage.getItem(path)
    }
    if (encoded) {
        const textToChars = (value) => value.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code) => textToChars('subpath').reduce((a, b) => a ^ b, code);
        return encoded.match(/.{1,2}/g).map((hex) => parseInt(hex, 16)).map(applySaltToChar).map((charCode) => String.fromCharCode(charCode)).join("");
    } else {
        return null
    }
};

export const EncryptGlobal = (text, path) => {
    localStorage.removeItem(path);
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars('subpath').reduce((a, b) => a ^ b, code);
    let encriptString = text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
    localStorage.setItem(path, encriptString)
};

export const decryptGlobal = (path) => {
    let encoded = localStorage.getItem(path)
    if (encoded) {
        const textToChars = (value) => value.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code) => textToChars('subpath').reduce((a, b) => a ^ b, code);
        return encoded.match(/.{1,2}/g).map((hex) => parseInt(hex, 16)).map(applySaltToChar).map((charCode) => String.fromCharCode(charCode)).join("");
    } else {
        return null
    }
};