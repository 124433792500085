import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FormControl, Input, InputAdornment,FormHelperText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoader } from '../../../_redux/LoaderSlice';
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import MessageBar from '../../../components/SnackBar/MessageBar'
import Loader from '../../../components/Loader/Loader';
import * as _ser from "../../../_redux/_services/UserManagerSlice"
import { sendCode } from '../../../_redux/CognitoSlice';
import * as Yup from "yup";
import { useFormik } from "formik";

import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState({
    userEmail: "",
  });
  const showLoader = useSelector((state) => state.Loader.value);
  const showMessage = useSelector((state) => state.Snackbar.message);
  const showMsgType = useSelector((state) => state.Snackbar.type);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validRegex = /^[a-zA-Z0-9_!#$%&*+/=?`{}~^.-]+@[a-zA-Z0-9.-]+$/
  const validationSchema = Yup.object({
    userEmail: Yup.string().email("Please enter a valid email address")
    .matches(validRegex,"Invalid Email format")
    .required('Please enter a registered email address'),
  });

  const formik = useFormik({
    initialValues: email,
    validationSchema,
    onSubmit: async (values) => {
      handleSubmit()
      // dispatch(_ser.userManagerStartReducer(values));
      // dispatch(_ser.userManagerStartReducer({}))
    },
  });

  const handleChange = (e) => {
    let value = e.target.value;
    // console.log(value.trim())
    setEmail({ userEmail: value.trim() });
    formik.values.userEmail = value.trim();
  }

  const handleSubmit = async () => {
    // e.preventDefault();
      if (email.userEmail) {
        dispatch(updateLoader(true));
        dispatch(updateSnackbar({ type: '', message: "" }));
        dispatch(sendCode(email.userEmail.trim())).then(res => {
          dispatch(updateLoader(false));
          dispatch(updateSnackbar({ type: 'success', message: "OTP sent successfully" }));
          setTimeout(() => {
            navigate('/ats/verify-otp', { state: { email: email.userEmail.trim() } });
            dispatch(_ser.userManagerStartReducer({}))
          }, 3000)
        }).catch(err => {
          dispatch(updateLoader(false));
          let msg = sessionStorage.getItem("cognitoMsg");
          dispatch(updateSnackbar({ type: 'error', message: msg }));
          sessionStorage.removeItem("cognitoMsg");
        });
      }
     else {
      dispatch(updateSnackbar({ type: 'error', message: "Invalid email address" }));
    }
  }

  return (
    <div className='registerBackground1'>
      <div className='BGImg1'>
        <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.svg"} alt="" />
        <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.1.svg"} alt="" />
      </div>
      {showMessage !== "" ? <MessageBar message={showMessage} type={showMsgType} /> : ''}
      {showLoader ? <Loader /> : ''}
      <div className='BGImg2'>
        {/* <h1>Forgot password</h1>
        <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</p> */}
        <img src={process.env.PUBLIC_URL + "/assests/img/BGImg2.svg"} alt="" />

      </div>
      <div className='registration-container2'>
        <div className='text-center m-2 d-flex flex-column justify-content-center align-items-center'>
          <div> <img src={process.env.PUBLIC_URL + "/assests/img/HirewingLogin.svg"} alt="" className='hirewinglogoforgotpassword' /></div>
          <br />
          <img src={process.env.PUBLIC_URL + "/assests/usericon1.svg"} alt="" className='usericon1' />
          <h3>Enter Email ID</h3>
          {/* <br /><br /><br /><br /> */}

          <form onSubmit={formik.handleSubmit} className='d-flex justify-content-start flex-column text-left m-2' style={{ width: "72%", margin: "7%", marginLeft: "11px" }}>
            {/* <div className='d-flex justify-content-center text-center'> */}
            <div className='linewidth' style={{ Width: "75%" }}>
              <FormControl variant="standard" fullWidth>
                <Input
                  placeholder="Email address"
                  id="input-with-icon-adornment"
                  // type="email"
                  autoComplete="off"
                  name="userEmail"
                  className='placeHolderemail'
                  value={email.userEmail} 
                  onBlur={formik.handleBlur}
                  error={formik.errors.userEmail && formik.touched.userEmail ? true : false}
                  onChange={handleChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={process.env.PUBLIC_URL + "/assests/usericon2.svg"} alt="user icon" />
                    </InputAdornment>
                  }
                />
                {formik.errors.userEmail && formik.touched.userEmail ? <FormHelperText className='errorHelperText'>{formik.errors.userEmail}</FormHelperText> : ""}
              </FormControl>
            </div>
            {/* </div>  */}
            <br /><br />
            <div className='text-center' style={{ marginLeft: "17%" }}>
              <button type="submit" className="btn-submit2 m-2">
                Submit
              </button>
              <Link to="/ats/login" type="button" className='link'>
                Back to login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>

  )
}

export default ForgotPassword

