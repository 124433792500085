import { createSlice } from "@reduxjs/toolkit";
import { updateLoader } from "../../_redux/LoaderSlice";
import { updateSnackbar } from "../../_redux/SnackbarSlice";
import { Encrypt, decrypt } from "../../_utilities/_encryptDecryptHelper";
import * as _ser from '../_apiHelpers/UserManagerApihelper';
const initalState = { data: null, message: '', code: null }
const initialSnackbar = { type: "", message: "" };


export const userManagerSlice = createSlice({
    name: "UserManager",
    initialState: initalState,
    reducers: {
        userManagerStartReducer: (state, action) => {
            return { ...state, data: null }
        },
        userManagerReducer: (state, action) => {
            if (action.payload.type !== '') {
                return { ...state, data: action.payload.username, code: action.payload.type,
                    message:    action.payload.type === "Generate OTP" ? "OTP sent successful" :
                                action.payload.type === "Otp Verified" ? "OTP verified successful" :
                                action.payload.type === "passwordChanged" ? "Password changed successful" : ''
                };
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { userManagerReducer, userManagerStartReducer } = userManagerSlice.actions;
export const userManagerAsync = (data, type) => async (dispatch) => {
    dispatch(updateLoader(true));
    if (type === "forgotpassword") {
        const res = await _ser.ForgotPassword(data);
        dispatch(userManagerReducer(res));
    }
    else if (type === "verifyOtp") {
        const res = await _ser.VerifyOtp(data);
        dispatch(userManagerReducer(res));
    }
    else if (type === "generatePassword") {
        const res = await _ser.GeneratePassword(data);
        dispatch(userManagerReducer(res));
        // dispatch(updateSnackbar({ type: 'success', message: "Password updated successfully !!!" }));
    }
    dispatch(updateLoader(false));
}


export const getCustDetailsSlice = createSlice({
    name: "GetCustDetails",
    initialState: initalState,
    reducers: {
        getCustDetailsStartReducer: (state, action) => {
            return { ...state, data: null }
        },
        getCustDetailsReducer: (state, action) => {
            if (action.payload.status === 200) {
                const myData = action.payload.data.user;
                Encrypt(JSON.stringify(myData), "GetUserInfo")
                Encrypt(JSON.stringify(myData[0].id), "GetUserId")
                Encrypt(JSON.stringify(myData[0].companyName), "GetUserName")
                return { ...state, data: myData, code: action.payload.data?.code, message: action.payload.data?.message };
            } else {
                let GetUserInfo = JSON.parse(decrypt("GetUserInfo"));
                if (GetUserInfo.length > 0) {
                    state = { ...state, data: GetUserInfo, message: action.payload.message }
                } else {
                    state = { ...state, message: action.payload.message, data: null }

                }
            }
        }
    }
});
export const { getCustDetailsReducer, getCustDetailsStartReducer } = getCustDetailsSlice.actions;
export const getCustDetailsAsync = (data) => async (dispatch) => {
    const res = await _ser.GetCustomerId(data);
    dispatch(getCustDetailsReducer(res));
    dispatch(updateLoader(false));
}


export const loginPageSlice = createSlice({
    name: "LoginPage",
    initialState: initalState,
    reducers: {
        loginPageStartReducer: (state, action) => {
            return { ...state, data: null }
        },
        loginPageReducer: (state, action) => {
            if (action.payload.status === 200) {
                return { ...state, data: action.payload.data?.data, code: action.payload.data?.code, message: action.payload.data?.message };
            } else {
                alert('Customer Data Not found please login again....');
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { loginPageReducer, loginPageStartReducer } = loginPageSlice.actions;
export const loginPageAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    const res = await _ser.LoginPage(data);
    dispatch(loginPageReducer(res));
    dispatch(updateLoader(false));
    dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const customFieldsSlice = createSlice({
    name: "CustomFields",
    initialState: initalState,
    reducers: {
        customFieldsStartReducer: (state, action) => {
            return { ...state, data: null }
        },
        customFieldsReducer: (state, action) => {
            if (action.payload.status === 200) {
                return { ...state, data: action.payload.data?.customfeilds, code: action.payload.data?.code, message: action.payload.data?.message };
            } else {
                alert('Customer Data Not found please login again....');
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { customFieldsReducer, customFieldsStartReducer } = customFieldsSlice.actions;
export const customFieldsAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    const res = await _ser.GetCustomFields(id);
    dispatch(customFieldsReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const getEmailTemplatesSlice = createSlice({
    name: "GetEmailTemplates",
    initialState: initalState,
    reducers: {
        getEmailTemplatesStartReducer: (state, action) => {
            return { ...state, data: null }
        },
        getEmailTemplatesReducer: (state, action) => {
            if (action.payload.status === 200) {
                return { ...state, data: action.payload.data?.emailtemplates, message: action.payload.data?.message };
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getEmailTemplatesReducer, getEmailTemplatesStartReducer } = getEmailTemplatesSlice.actions;
export const getEmailTemplatesAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    const res = await _ser.GetEmailTemplates(id);
    dispatch(getEmailTemplatesReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const getEmailTemplatebyIdSlice = createSlice({
    name: "GetEmailTemplatebyId",
    initialState: initalState,
    reducers: {
        getEmailTemplatebyIdStartReducer: (state, action) => {
            return { ...state, data: null }
        },
        getEmailTemplatebyIdReducer: (state, action) => {
            if (action.payload.status === 200) {
                return { ...state, data: action.payload.data?.emailtemplates, message: action.payload.data?.message };
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getEmailTemplatebyIdReducer, getEmailTemplatebyIdStartReducer } = getEmailTemplatebyIdSlice.actions;
export const getEmailTemplatebyIdAsync = (customerId, id) => async (dispatch) => {
    dispatch(updateLoader(true));
    const res = await _ser.GetEmailTemplatebyId(customerId, id);
    dispatch(getEmailTemplatebyIdReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const SendEmailSlice = createSlice({
    name: "SendEmail",
    initialState: initalState,
    reducers: {
        SendEmailStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        SendEmailReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { SendEmailReducer, SendEmailStartReducer } = SendEmailSlice.actions;
export const SendEmailAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.SendEmail(data);
    dispatch(SendEmailReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
    }, 0);
}

export const BulkEmailSlice = createSlice({
    name: "BulkEmail",
    initialState: initalState,
    reducers: {
        BulkEmailStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        BulkEmailReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { BulkEmailReducer, BulkEmailStartReducer } = BulkEmailSlice.actions;
export const BulkEmailAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.BulkEmail(data);
    dispatch(BulkEmailReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
    }, 0);
}