import React, { useState, useEffect } from 'react'
import './ResetPassword.css'
import { FormControl, Input, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import MessageBar from '../../../components/SnackBar/MessageBar'
import Loader from '../../../components/Loader/Loader'
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import * as _ser from '../../../_redux/_services/UserManagerSlice';
import { changePassword } from '../../../_redux/CognitoSlice';

import * as Yup from "yup";
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import { updateLoader } from '../../../_redux/LoaderSlice';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [selectedTab, setSelectedTab] = useState(0);
  const location = useLocation();
  const showLoader = useSelector((state) => state.Loader.value);
  const showMessage = useSelector((state) => state.Snackbar.message);
  const showMsgType = useSelector((state) => state.Snackbar.type);
  const { data, code, message } = useSelector((state) => state.UserManager);

  const [userDetails, setUserDetails] = useState({
    userName: "",
    role: 'user',
    password: "",
    confirmPassword: "",
    type: "resetPassword"
  })

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
      password: Yup.string()
        .required('Please enter a password')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
      confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and confirm password does not match"
        )
      }).required('Please enter a confirm password')
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
  })

  const formik = useFormik({
    initialValues: userDetails,
    validationSchema,
    onSubmit: async (values) => {
      dispatch(updateLoader(true));
      dispatch(updateSnackbar({ type: '', message: "" }));
      dispatch(changePassword(values.password, location.state.oldPassword, location.state.email)).then(res=>{
        dispatch(updateLoader(false));
        dispatch(updateSnackbar({ type: 'success', message: "Password reset successful" }));
        setTimeout(()=>{
          navigate("/ats/login");
          dispatch(_ser.userManagerStartReducer({}))
        }, 3000)
      }).catch(err => {
        dispatch(updateLoader(false));
        let msg = sessionStorage.getItem("cognitoMsg");
        dispatch(updateSnackbar({ type: 'error', message: msg }));
        sessionStorage.removeItem("cognitoMsg");
      });
    },
  });

  const handleChange = (e) => {
    setUserDetails((userDetails) => ({ ...userDetails, [e.target.name]: e.target.value }));
    formik.values[e.target.name] = e.target.value;
  }

  return (
    <div className='registerBackground-respass'>
       <div className='BGImg1respass'>
          <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.svg"} alt="" />
          <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.1.svg"} alt="" />
        </div>
      {showMessage !== "" ? <MessageBar message={showMessage} type={showMsgType} /> : ''}
      {showLoader ? <Loader /> : ''}
      <FocusError formik={formik} />
      <div className='BGImg2respass'>
        {/* <h1>Reset Password</h1>
        <p>The word 'lorem' for example, isn't a real Latin word,</p> */}
        <img src={process.env.PUBLIC_URL + "/assests/img/BGImg2.svg"} alt="" />
      </div>
      <div className='registration-container-resetpass'>
        <div 
        // className='text-center m-2 d-flex flex-column justify-content-center align-items-center'
        className='passcontent-section'
         
         >
        <div > <img src={process.env.PUBLIC_URL + "/assests/img/HirewingLogin.svg"} alt="" className='hirewinglogoresetpage' /></div>
           {/* <br/>
           <br/> */}
           <img src={process.env.PUBLIC_URL + "/assests/usericon1.svg"} alt="" className='usericonlogin'  />
          <h3>Reset Password</h3>
       
          <div className='text-center ' style={{width:"70%"}}>
            <form 
            // className='d-flex justify-content-start flex-column text-left' 
            className='password-fieldcss' 
            >
              <div className='m-2' style={{width:"100%"}}>
                <FormControl variant="standard" 
                // style={{width: "350px"}}
                fullWidth
                >
                  <Input
                    placeholder='Password'
                    id="input-with-icon-adornment"
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    required
                    name="password"
                    value={userDetails.password}
                    onBlur={formik.handleBlur}
                    error={ formik.errors.password && formik.touched.password ? true : false }
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={process.env.PUBLIC_URL + "/assests/lockicon.svg"} alt="lock icon" />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  { formik.errors.password && formik.touched.password ?  <FormHelperText className='errorHelperText'>{formik.errors.password}</FormHelperText> : "" }
                </FormControl>
              </div>
              <div className='' style={{marginTop:'10px', width:"100%"}} >
                <FormControl variant="standard"  
                // style={{width: "350px"}}
                fullWidth
                >
                  <Input
                    placeholder='Confirm password'
                    id="input-with-icon-adornment"
                    autoComplete="off"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    name="confirmPassword"
                    value={userDetails.confirmPassword}
                    onBlur={formik.handleBlur}
                    error={ formik.errors.confirmPassword && formik.touched.confirmPassword ? true : false }
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={process.env.PUBLIC_URL + "/assests/lockicon.svg" }alt="lock icon" />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  { formik.errors.confirmPassword && formik.touched.confirmPassword ?  <FormHelperText className='errorHelperText'>{formik.errors.confirmPassword}</FormHelperText> : "" }
                </FormControl>
              </div>
              <div className='text-center' style={{margin:"5% 0%"}}>
                <button type="submit" className="btn-submitrespass btn-submitlogin m-2" onClick={formik.handleSubmit}>
                  Reset
                </button>
                <Link to="/ats/login" type="button" className='linktologin'>
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default ResetPassword

