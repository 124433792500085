import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { InputAdornment, Select, TextField, MenuItem } from "@mui/material";
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import { useDispatch, useSelector } from "react-redux";
import { getCountriesAsync, getCountriesStartReducer } from "../../../_redux/_services/JobPostingSlice";
import { getCustDetailsAsync } from "../../../_redux/_services/UserManagerSlice";
import { updateLoader } from "../../../_redux/LoaderSlice";
import { updateSnackbar } from "../../../_redux/SnackbarSlice";

const initialSnackbar = { type: "", message: "" };

const PostingsPortals = ({ componentType, onReceiveHandler, data, flatFee, flatFeeCountryId, updateHandler, updateCountryHandler }) => {

  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [countriesList, setCountriesList] = useState([]);
  const countriesRes = useSelector((state) => state.GetCountries.data);
  const [portals, setPortals] = useState([]);
  const [jobPortaldata, setJobPortaldata] = useState([]);
  
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const dispatch = useDispatch();
  const onChangePlatformHandler = (e, item) => {
    onReceiveHandler(e, item);
  }

  const getData = async (id) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customers/jobBoard`, {
      method: "GET",
      headers: { 'Content-Type': 'Application/json', "customerId": id },
    });
    const jsonData = await res.json();
    if (jsonData.jobBoardData.length > 0) {
      setJobPortaldata(jsonData.jobBoardData[0]);
      console.log(jsonData.jobBoardData[0]);
    }
    dispatch(updateLoader(false));
  };

  const atsCustomerDetails = useSelector((state) => state.GetCustDetails.data);

  useEffect(()=>{
    if(data.length > 0 && atsCustomerDetails){
    // let userDetails = JSON.parse(decrypt("GetUserInfo"));
    let portals = JSON.parse(atsCustomerDetails[0].portals);
    let portalIds = portals[0].portal;
    // console.log(portalIds);
    let portalArray = [];
    data.forEach((list)=>{
      if( portalIds.includes((list.id).toString()) ) {
        portalArray.push(list);
      }
      if(jobPortaldata.isJobPortal === 1 && list.id === 5){
        portalArray.push(list);
      }
      setPortals(portalArray);
    })
  }
  },[data, atsCustomerDetails, jobPortaldata])

  const onChangeHandler = (value) => {
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      updateHandler(value);
    }
  };

  useEffect(() => {
    if (!countriesRes) {
      dispatch(getCountriesAsync());
    }
    let userInfo = JSON.parse(decrypt("GetUserId"));
    let USERId = { userId: userInfo };
    dispatch(getCustDetailsAsync(USERId));
    getData(userInfo);
    console.log(userInfo);
  }, []);

  useEffect(() => {
    if (countriesRes && countriesRes.length > 0) {
      let baseref = window.location.href.split(window.location.pathname);
      let ListofCountries = JSON.parse(JSON.stringify(countriesRes));
      ListofCountries.forEach((x) => {
        x["name"] = x.countryName;
        x["id"] = parseInt(x.id);
        x["image"] = `${baseref[0]}${process.env.PUBLIC_URL}/assests/img/${getCountryFlag(x.countryName)}`
        x["currencyName"] = x.countryName === "Canada" ? "CAD" : x.countryName === "India" ? "IND" : x.countryName === "USA" ? "USD" : x.countryName === "Malaysia" ? "MYR" : "";
      });
      console.log(ListofCountries);
      setCountriesList(ListofCountries);
      getCountriesStartReducer({})
    }
  }, [countriesRes])

  const getCountryFlag = (counttyName) => {
    if (counttyName === "India") {
      return '001-india.svg'
    } else if (counttyName === "USA") {
      return '002-usa.svg'
    } else if (counttyName === "Canada") {
      return '003-canada.svg'
    }
    else if (counttyName === "Malaysia") {
      return '004-malaysia.svg'
    }
  }

  const onCurrencyTypeChangeHandler = (e) => {
    setSelectedCurrency(e.target.value);
    updateCountryHandler(e.target.value);
  }

  useEffect(() => {
    setSelectedCurrency(flatFeeCountryId);
  }, [flatFeeCountryId])

  return (
    <>
      <div className="portalsSection">
        {portals.length > 0 ? 
        <h4 className="subHeading" style={{ marginBottom: "60px" }}>
          Please select the portals to display the job posting
        </h4> :
        <h4 className="subHeading" style={{ marginBottom: "60px" }}>
        No Portals Found. Please Contact Admin to add portals options.
        </h4> }
        {portals && portals.map((item) => (
          <>
            <p key={item.id}>
              {item.title}{" "}
              <Switch
                {...label}
                checked={item.checked}
                disabled={componentType !== "View" ? false : true}
                onChange={(e) => onChangePlatformHandler(e, item)}
              />
            </p>
            {item.name === "CSPortal" && parseInt(item.value) === 1 && (
              <div style={{ width: "23%" }}>
                <TextField
                  className="boldlabel"
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  label="Flat Fee"
                  style={{ width: "100%" }}
                  name="flatFee"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          id="outlined-basic"
                          className="innerDropdown"
                          value={selectedCurrency}
                          size="small"
                          name="module"
                          onChange={onCurrencyTypeChangeHandler}
                          disabled={componentType !== "View" ? false : true}
                        >
                          {countriesList &&
                            countriesList.map((name) => (
                              <MenuItem className="selectOptions"
                                key={name.id} value={name.id}>
                                <img src={name.image} alt={name.name} width="20px" style={{ paddingRight: "7px" }} />{name.currencyName}
                              </MenuItem>
                            ))}
                        </Select>
                        {selectedCurrency === 1 ? <span className="text-secondary">&#8377;</span>
                          : selectedCurrency === 4 ? <span className="text-secondary">&#82;&#77;</span> : <span className="text-secondary">&#36;</span>}
                      </InputAdornment>
                    ),
                  }}
                  disabled={componentType !== "View" ? false : true}
                  value={parseInt(flatFee) === 0 ? "" : flatFee}
                  onChange={(e) => onChangeHandler(e.target.value)}
                />
              </div>
            )}
          </>
        ))}
      </div>
    </>
  );
};
export default React.memo(PostingsPortals);