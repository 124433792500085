import { Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from '../_redux/CognitoSlice';
import GetJobId from '../_utilities/GetJobId';
import { decrypt } from '../_utilities/_encryptDecryptHelper';
import { applicantsMenus, clientsMenus, customerMenus, helpSectionMenus, jobPostingMenus } from './layoutdata';


function NavSidebar({ drawerWidth, menuList, menuType, isAssociat, subPathName }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeMenu, setActiveMenu] = useState(null)
    const getJobId = GetJobId();
    const location = useLocation();
    const nextPathValue = useSelector((state) => state.SeletedNextPath.value);
    const [nextpath, setNextpath] = useState("");
    const [ subpath, setSubPath ] = useState('');

    const onNavigateHandle = (item) => {
        if( item.url.includes("helpsection") ) {
            setActiveMenu("helpsection");
            navigate(`${item.url}`);
        }else if( item.url.includes("custom") ) {
            setActiveMenu(item.menuName);
            navigate(`${item.url}`);
        } else {
            setActiveMenu(item.menuName);
            navigate(`${item.url}/${isAssociat !== "" ? parseInt(isAssociat) : getJobId}`);
        }
    }

    useEffect(() => {
        let customerSubpathObj = JSON.parse(decrypt("GetUserInfo"));
        const url = location.pathname;
        const path = url.substring(url.lastIndexOf("/") + 1);
        let comparePath = "";
        if (Number.isInteger(parseInt(path))) {
         comparePath = removelastSlash(url);
        } else {
         comparePath = url.replace("/ats/layout/", "");
        }
        let filteredData = jobPostingMenus.filter(x => x.url === comparePath || x.urls === comparePath);
        let filteredData1 = applicantsMenus.filter(x => x.url === comparePath || x.urls === comparePath);
        let filteredData2 = clientsMenus.filter(x => x.url === comparePath || x.urls === comparePath);
        let filteredData3 = helpSectionMenus.filter(x => x.url === comparePath  || x.urls === comparePath);
        let filteredData4 = customerMenus.filter(x => x.url === comparePath  || x.urls === comparePath);
        if (filteredData.length > 0) {
            setActiveMenu(filteredData[0].menuName);
        } else if (filteredData1.length > 0) {
            setActiveMenu(filteredData1[0].menuName);
        } else if (filteredData2.length > 0) {
            setActiveMenu(filteredData2[0].menuName);
        } else if (filteredData3.length > 0) {
            setActiveMenu(filteredData3[0].menuName);
        }else if (filteredData4.length > 0) {
            setActiveMenu(filteredData4[0].menuName);
        } 
        else {
            setActiveMenu(null);
        }
        if (Number.isInteger(parseInt(path))) {
            let subpathObj = JSON.parse(localStorage.getItem("subpathObj"));
            let subpathType = (menuType === 'jobpostings') ? "Job Code" : (menuType === 'applicants') ? "Applicant Code" : "clients";
            if(subpathObj && subpathObj.length > 0 && menuType !== "helpsection" && menuType !== "customer"){
                let filterSubObj = subpathObj.filter(x => x.id === parseInt(path) && x.type === subpathType);
                if (filterSubObj.length > 0) {
                    setSubPath(filterSubObj[0].name);
                }
            }
             else {
                signOut();
                navigate("/ats/login");
                localStorage.clear();
                sessionStorage.clear();
            }
        }else if(customerSubpathObj && customerSubpathObj.length > 0){
            let subpath = { id: customerSubpathObj[0].id, name: customerSubpathObj[0].companyName, icon: "", type: "" }
            setSubPath(subpath.name);
        } else {
            setSubPath("Categories");   // TO DO
        }
    }, [location])

    const removelastSlash = (str) => {
        let path = str.replace("/ats/layout/", "");
        return path.slice(0, path.lastIndexOf('/'));
    }

    useEffect(() => {
        if (nextPathValue !== '' && nextPathValue !== 'null') {
            setNextpath(nextPathValue);
        } else {
            setNextpath("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPathValue])

    return (
        <>
            {menuList !== null ?
                <Drawer variant="permanent" className="navbarSection" sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', top: "70px", width: drawerWidth, boxShadow: "0px 3px 6px #00000029" } }} open >
                    {menuType === 'jobpostings' ?
                        <div className="navbarHedding">
                            <img src={process.env.PUBLIC_URL + "/assests/img/suitcase.svg"} alt="suitcase" className="postingSuitcase" />
                            <span title={subpath ? subpath : subPathName}>{subpath ? subpath : subPathName}</span>
                            {/* Job Postings */}
                        </div>
                        : menuType === 'applicants' ?
                            <div className="navbarHedding">
                                <img src={process.env.PUBLIC_URL + "/assests/img/applicantsIcon.svg"} alt="applicantsIcon" className="applicantSuitcase" />
                                <span title={nextpath !== "" ? nextpath : subpath ? subpath : subPathName}>{nextpath !== "" ? nextpath : subpath ? subpath : subPathName}</span>
                                {/* Applicants */}
                            </div> : menuType === 'clients' ?
                                <div className="navbarHeddingclients">
                                    {/* <br/> */}
                                    <img src={process.env.PUBLIC_URL + "/assests/img/suitcase.svg"} alt="suitcase" className="applicantSuitcase" />
                                    <span title={subpath ? subpath : subPathName}>{subpath ? subpath : subPathName}</span>
                                    {/* Clients */}
                                </div> : menuType === 'helpsection' ?
                                    <div className="navbarHedding">
                                        {/* <br/> */}
                                        <img src={process.env.PUBLIC_URL + "/assests/img/video.svg"} alt="suitcase" className="applicantSuitcase" />
                                        {/* <span title={subpath}>{subpath}</span> */}
                                        Categories
                                        {/* helpsection */}
                                    </div> : menuType === 'customer' ?
                                    <div className="navbarHedding">
                                        {/* <br/> */}
                                        <img src={process.env.PUBLIC_URL + "/assests/img/suitcase.svg"} alt="suitcase" className="applicantSuitcase" />
                                        <span title={subpath ? subpath : subPathName }>{subpath ? subpath : subPathName }</span>
                                        {/* customer */}
                                    </div> :''
                    }
                    <List>
                        {menuList && menuList.map((item, index) => {
                            if (isAssociat !== "") {
                                if (item.menuName !== "Associated Jobs" && item.menuName !== "Associated Applicants") {
                                    return (
                                        <ListItem key={index} onClick={() => onNavigateHandle(item)} className={(activeMenu === item.menuName) ? 'active navbarList' : 'navbarList'}>
                                            <ListItemButton>
                                                <img src={(activeMenu === item.menuName) ? item.activeImg : item.img} alt={item.menuName} />
                                                <ListItemText primary={item.menuName} />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                }
                            } else {
                                return (
                                    <ListItem key={index} onClick={() => onNavigateHandle(item)} className={(activeMenu === item.menuName) ? 'active navbarList' : 'navbarList'}>
                                        <ListItemButton>
                                            <img src={(activeMenu === item.menuName) ? item.activeImg : item.img} alt={item.menuName} />
                                            <ListItemText primary={item.menuName} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                        })}
                    </List>
                </Drawer> : null
            }
        </>
    );
}

export default NavSidebar;