import { deleteApi, getApi, postApi, putApi } from "./_apihelper";

export const UploadResume = (data, isFormData) => (
    postApi(process.env.REACT_APP_UPLOAD_RESUME, data, process.env.REACT_APP_JOBSEEKER, isFormData) 
)

export const BulkUploadResume = (data, isFormData, jobObj) => (
    postApi(process.env.REACT_APP_BULKUPLOAD_RESUME, data, process.env.REACT_APP_JOBSEEKER, isFormData, jobObj) 
)

export const DuplicateResume = (data, isFormData) => (
    postApi(process.env.REACT_APP_DUPLICATE_JOBSEEKER, data, process.env.REACT_APP_JOBSEEKER, isFormData) 
)

export const AddApplicants = (data, isFormData) => (
    postApi(process.env.REACT_APP_APPLICANTS, data, process.env.REACT_APP_JOBSEEKER, isFormData)
)

export const EditApplicants = (id, data) => (
    putApi(`${process.env.REACT_APP_APPLICANTS}/${id}`, data, process.env.REACT_APP_JOBSEEKER) 
)

export const GetListApplicants = (limit, offset) => (
    getApi(`${process.env.REACT_APP_APPLICANTS}?limit=${limit}&offset=${offset}`, process.env.REACT_APP_JOBSEEKER) 
)

export const SearchApplicantsWithoutPaging = (object, url) => {
    if(url === undefined){
        return getApi(`${process.env.REACT_APP_APPLICANTS}?keyword=${object.keyword}`, process.env.REACT_APP_JOBSEEKER) 
    } else {
        // return getApi(`${process.env.REACT_APP_APPLICANTS}?${url}`, process.env.REACT_APP_JOBSEEKER) 
        return getApi(`${process.env.REACT_APP_JOB_POSTINGS}/affinda/search?${url}&limit=${object.limitData}&offset=${object.offset}`, process.env.REACT_APP_JOBOPENING) 
    }
}

export const SearchApplicantsWithPaging = (limit, offset, keyword, url) => {
    if(url !== undefined){
        return (getApi(`${process.env.REACT_APP_APPLICANTS}?limit=${limit}&offset=${offset}&${url}`, process.env.REACT_APP_JOBSEEKER))
    } else {
        return (getApi(`${process.env.REACT_APP_APPLICANTS}?limit=${limit}&offset=${offset}&keyword=${keyword}`, process.env.REACT_APP_JOBSEEKER))
    }
     
}

export const DeleteApplicants = (data) => (
    deleteApi(process.env.REACT_APP_APPLICANTS, data, process.env.REACT_APP_JOBSEEKER) 
)

export const ApplicantsById = (data) => (
    getApi(`${process.env.REACT_APP_APPLICANTS}/${data}`, process.env.REACT_APP_JOBSEEKER) 
)


export const GetApplicantJobs = (id) => (
    getApi(`${process.env.REACT_APP_APPLICANTS}/${id}/jobopening`, process.env.REACT_APP_JOBSEEKER) 
)

export const DeleteApplicantJobs = (id, data, deleteIds) => (
    deleteApi(`${process.env.REACT_APP_APPLICANTS}/${id}/jobopening/${data.id}`, deleteIds, process.env.REACT_APP_JOBSEEKER)  
)

export const GetMatchAssociatedJobs = (id) => (
    getApi(`${process.env.REACT_APP_APPLICANTS}/${id}${process.env.REACT_APP_MATCH_ASSOCIATED_JOBS}`, process.env.REACT_APP_JOBSEEKER)  
)


export const DeleteAssociatedjobs = (jobId, obj) => (
    deleteApi(`${process.env.REACT_APP_APPLICANTS}/${jobId}/jobopening/${obj.id}`, obj, process.env.REACT_APP_JOBSEEKER)  
)

export const AddAssociatedjobs = (jobId, obj) => (
    postApi(`${process.env.REACT_APP_APPLICANTS}/${jobId}/jobopening/${obj.id}`, obj, process.env.REACT_APP_JOBSEEKER)  
)

export const GetDocuments = (id) => (
    getApi(`${process.env.REACT_APP_APPLICANTS}/${id}/document`, process.env.REACT_APP_JOBSEEKER)  
)

export const DeleteDocuments = (id, data) => (
    deleteApi(`${process.env.REACT_APP_APPLICANTS}/${id}/document`, data, process.env.REACT_APP_JOBSEEKER) 
)

export const Downloaddocument = (id, url,type) => (
    postApi(`${process.env.REACT_APP_APPLICANTS}/documentdownload`, { path:url, id:id , action:type}, process.env.REACT_APP_JOBSEEKER) 
)

export const UploadDocuments = (id, data, isFormData) => (
    postApi(`${process.env.REACT_APP_APPLICANTS}/${id}/document`, data, process.env.REACT_APP_JOBSEEKER, isFormData)
)

// export const DeleteDocuments = (data) => (
//     deleteApi(process.env.REACT_APP_DELETE_DOCUMENTS, data)
// )
// export const GetDocuments = (data) => (
//     getApi(`${process.env.REACT_APP_GET_DOCUMENTS}/${data.id}`)
// )

// export const Downloaddocuments = (data) => (
//     putApi(process.env.REACT_APP_GET_DOCUMENTS, {body : {...data}})
// )

export const AddNotes = (data) => (
    postApi(process.env.REACT_APP_ADD_NOTES, data, process.env.REACT_APP_JOBSEEKER) 
)

export const EditNotes = (data) => (
    putApi(`${process.env.REACT_APP_ADD_NOTES}/${data.id}`, data, process.env.REACT_APP_JOBSEEKER) 
)

export const ListNotes = (id) => (
    getApi(`${process.env.REACT_APP_APPLICANTS}/${id}/notes`, process.env.REACT_APP_JOBSEEKER) 
)

export const GetAssociatedApplicants = (data) => (
    getApi(`${process.env.REACT_APP_APPLICANTS}/${data.id}/notes${process.env.REACT_APP_ASSOCIATED_JOBS}`, process.env.REACT_APP_JOBSEEKER)
)

export const AddapplicantsForAssociatedJobs = (data, id, isFormData) => (
    postApi(`${process.env.REACT_APP_ADD_APPLICANTS_FOR_ASSOCIATEDJOBS}/${id}`, data, process.env.REACT_APP_JOBSEEKER, isFormData)
)

export const GetApplicantsComments = (data) => (
    getApi(`${process.env.REACT_APP_JOBSEEKER_COMMENTS}?type=${data.type}&typeId=${data.typeId}`, process.env.REACT_APP_JOBSEEKER)
)

export const GetBarChartData = (data) => (
    getApi(`${process.env.REACT_APP_BARCHART}?startDate=${data.startDate}&endDate=${data.endDate}&userId=${data.userId}`, process.env.REACT_APP_REPORTS)
)

export const GetPieChartData = (data) => (
    getApi(`${process.env.REACT_APP_PIECHART}?startDate=${data.startDate}&endDate=${data.endDate}&userId=${data.userId}`, process.env.REACT_APP_REPORTS)
)

export const GetRequirementAssigned = (data) => (
    getApi(`${process.env.REACT_APP_ASSIGNED_REQUIREMENT}?userId=${data.userId}&limit=${data.limit}&offset=${data.offSet}`, process.env.REACT_APP_JOBOPENING)
)

export const GetResumeCount = (object) => (
    getApi(`${process.env.REACT_APP_RESUMECOUNT}?startDate=${object.startDate}&endDate=${object.endDate}&userId=${object.userId}`, process.env.REACT_APP_REPORTS)
)