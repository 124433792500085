import React, { useState, useEffect } from "react";
import JobboardPortalComponent from "./JobboardPortalComponent";
import { useDispatch } from "react-redux";
import GetJobId from "../../../_utilities/GetJobId";
import { updateLoader } from "../../../_redux/LoaderSlice";
import { updateSnackbar } from "../../../_redux/SnackbarSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";

const JobboardPortalEdit = () => {
  const location = useLocation();
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const [initalData, setInitalData] = useState({
    logo: process.env.PUBLIC_URL + "/assests/nopreview.png",
    preview: "nopreview.png",
    isJobPortal: false,
    primaryData: initialPrimary,
    typography: initialTypography,
    url: process.env.REACT_APP_JOBBOARD_PORTAL_URL
  })

  const [selectedCustomer, setSelectedCustomer] = useState("");

  const [Data, setData] = useState(null);
  const navigate = useNavigate();
  let getJobCode = GetJobId();

  const initialSnackbar = { type: "", message: "" };

  const dispatch = useDispatch();

  // let userInfo = JSON.parse(decrypt("GetUserInfo"));

  const actionBtns = [{
    variant: "text",
    style: { color: "red", marginRight: "25px", fontWeight: "bolder", textTransform: "capitalize" },
    label: "Cancel",
    type: "back",
    class: 'buttonHover1',
    navigateTo: `/ats/layout/customer/jobboardportal/view`,
  },
  {
    variant: "contained",
    style: { backgroundColor: "#ffd800", color: "black", borderRadius: "25px", width: "6rem", textTransform: "capitalize" },
    navigateTo: null,
    label: "Save",
    type: "submit",
    class: 'buttonHover',
  }
  ]
  const getUserId = JSON.parse(decrypt("GetUserInfo"));


  useEffect(() => {
    // const url = location.pathname;
    // const path = url.substring(url.lastIndexOf("/") + 1);
    // if (Number.isInteger(parseInt(path))) {
      let newObj = JSON.parse(JSON.stringify(initalData))
      newObj.url = newObj.url + getUserId[0].id + "/layout/jobfeed";
      setInitalData(newObj);
    // }
    if (getUserId !== null) {
      getData(getUserId[0].id);
    }
  }, [location])

  useEffect(() => {
    if (getUserId !== null) {
      getData(getUserId[0].id);
    }
  }, []);

  const getData = async (id) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customers/jobBoard`, {
      method: "GET",
      headers: { 'Content-Type': 'Application/json', "customerId": id },
    });

    const jsonData = await res.json();
    if (jsonData.jobBoardData.length > 0) {
      let newData = jsonData.jobBoardData[0];
      newData.isJobPortal = parseInt(newData.isJobPortal) === 1 ? true : false;
      newData.primaryData = newData.primaryData ? JSON.parse(newData.primaryData) : initialPrimary;
      newData.typography = newData.typography ? JSON.parse(newData.typography) : initialTypography;
      newData.url = newData.url ? newData.url : initalData.url;
      newData.preview = newData.logo ? newData.logo.substring(newData.logo.lastIndexOf("/") + 1) : initalData.preview;
      if (newData.logo) {
        const res1 = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customers/previewDocument`, {
          method: "POST",
          headers: { 'Content-Type': 'Application/json', "customerId": id },
          body: JSON.stringify({ path: newData.logo })
        });
        const docData = await res1.json();
        if (docData.code === 200) {
          newData.logo = docData.path;
          setData(newData);
        }
      } else {
        newData.logo = initalData.logo;
        setData(newData);
      }
    }
    dispatch(updateLoader(false));
  };

  const onUpdateHandler = (data) => {
    setData(data);
  }

  const onSubmitHandler = async (data, logo) => {
    if(data.logo === "" && logo === null){
      dispatch(updateSnackbar({ type: "error", message: "Brand Logo is required!" }));
      return;
    }
    setDisableSubmitBtn(true)
    let formData = new FormData();
    formData.append("logo", logo ? logo : data.logo);
    formData.append("isJobPortal", Boolean(data.isJobPortal) ? "1" : "0");
    formData.append("primaryData", JSON.stringify(data.primaryData));
    formData.append("typography", JSON.stringify(data.typography));
    formData.append("url", data.url);
    formData.append("customerId", getUserId[0].id);
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customers/jobBoard`, {
      method: "POST",
      body: formData,
    });
    const jsonData = await res.json();
    dispatch(updateLoader(false));
    dispatch(updateSnackbar({ type: (jsonData.code === 200) ? "success" : "error", message: jsonData.message }));
    if (jsonData.code === 200) {
      setTimeout(() => {
        setDisableSubmitBtn(false)
        navigate(`/ats/layout/customer/jobboardportal/view`) }, 1000)
    } else {
        setDisableSubmitBtn(false)
    }
  }

  return (
    <React.Fragment>
      <JobboardPortalComponent actions={actionBtns} componentType="Edit" disableSubmitBtn={disableSubmitBtn}
        initial={initalData} data={Data} updateHandler={onUpdateHandler} handleSubmit={onSubmitHandler} />
    </React.Fragment>
  );
};

export default JobboardPortalEdit;

const initialPrimary = [
  { title: 'Primary Color', value: "#FFD800" },
  { title: 'Secondary Color', value: "#3B4046" },
]

const initialTypography = [
  {
    title: "Primary Font",
    name: "mainheading",
    fontName: '"Roboto", sans-serif',
    color: "#000000",
  },
  {
    title: "Secondary Font",
    name: "bodyheading",
    fontName: '"Roboto", sans-serif',
    color: "#000000",
  }
]