import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ser from '../../../_redux/_services/ClientsSlice';
import { setBreadCrumb } from '../../../_utilities/breadCrumb';
import GetJobId from '../../../_utilities/GetJobId';
import ClientsCreate from '../ClientsCreate/ClientsCreate';

const initalState = { data: null, message: '', isloader: true }

const ClientsView = () => {
  const getJobCode = GetJobId();

  const dispatch = useDispatch();
  const [getDetails, setGetDetails] = useState(initialDetails);


  const actionBtns = [{
    variant: "contained", label: "Edit", type: "back",
    style: { backgroundColor: "#ebc700", color: "#000000", borderRadius: "25px", width: "6rem", textTransform: "capitalize" },
    class: 'buttonHover1',
    navigateTo: `/ats/layout/clients/edit/${getJobCode}`
  }]

  useEffect(() => {
    if (getJobCode !== null && getDetails.clientName === '') {
      GetData();
    }
  }, [getJobCode])

  const onGetValidData = (data) => {
    console.log(data);
  }

  const MyData = useSelector(state => state.GetClients.data);

  const GetData = async () => {
    dispatch(_ser.getClientsAsync(getJobCode));
  };
  
  useEffect(()=>{
    if (MyData !== null && MyData.length > 0) {
      let myData = JSON.parse(JSON.stringify(MyData))
      setGetDetails(myData[0]);
      dispatch(_ser.getClientsStartReducer(initalState));
      setBreadCrumb(myData[0].id, myData[0].clientName, "clients" )
    }
  }, [MyData])

  return (
    <React.Fragment>
      <div style={{ padding: "0 10px" }} id="clientsView">
        {getDetails?.clientName !== '' ? (
          <ClientsCreate componentType="view" actions={actionBtns} onValidData={onGetValidData} input={getDetails} iniValue={initialDetails} />
        ) : <div className="nodatasection">
        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noData" />
        {"No data found"}</div>
      }
      </div>
    </React.Fragment>
  )
}

export default ClientsView;

const initialDetails = {
  clientName: "",
  // endClient:"",
  managerEmail: "",
  region: "",
  status: "",
  id: "",
  source: 1
} 