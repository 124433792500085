export const widgetsmokeData = [
    {
        id: 1,
        title : "Total Resume Contributed",
        currentValue: 0,
        currentTitle: "My Contribution",
        avgValue: "0",
        avgPercentage: 0,
        avgTitle: "Company's average",
        highestValue: "0",
        highestPercentage: 100,
        highestTitle: "Top Performer",
        img: `${process.env.PUBLIC_URL + "/assests/TotalContributed.svg"}`
    },
    {
        id: 2,
        title : "Total Submissions",
        currentValue: 0,
        currentTitle: "My Contribution",
        avgValue: "0",
        avgPercentage: 0,
        avgTitle: "Company's average",
        highestValue: "0",
        highestPercentage: 100,
        highestTitle: "Top Performer",
        img: `${process.env.PUBLIC_URL + "/assests/TotalSubmissions.svg"}`
    },
    {
        id: 3,
        title : "Total Profiles in Interview",
        currentValue: 0,
        currentTitle: "My Contribution",
        avgValue: "0",
        avgPercentage: 0,
        avgTitle: "Company's average",
        highestValue: "0",
        highestPercentage: 100,
        highestTitle: "Top Performer",
        img: `${process.env.PUBLIC_URL + "/assests/TotalInterview.svg"}`
    },
    {
        id: 4,
        title : "Total Profiles Selected",
        currentValue: 0,
        currentTitle: "My Contribution",
        avgValue: "0",
        avgTitle: "Company's average",
        highestValue: "0",
        avgPercentage: 0,
        highestPercentage: 100,
        highestTitle: "Top Performer",
        img: `${process.env.PUBLIC_URL + "/assests/TotalSelected.svg"}`
    }
]